.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  position: absolute;
  width: 100vw;
  top: 0;
  left: 0;

  .modal-container {
    position: fixed;
    background-color: white;
    width: 70%;
    max-width: 400px;
    left: 50%;
    padding: 20px;
    border-radius: 5px;
    -webkit-transform: translate(-50%, calc(50vh - 50%));
    -ms-transform: translate(-50%, calc(50vh - 50%));
    transform: translate(-50%, calc(50vh - 50%));
    -webkit-transition: -webkit-transform 200ms ease-out;
    transition: transform 200ms ease-out;
  }
}
