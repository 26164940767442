.component-link-button {
  color: rgb(0, 0, 238);
  color: -webkit-link;
  display: inline;
  -webkit-appearance: none;
  background-color: transparent;
  cursor: pointer;
  text-decoration: underline;
  border: none;
  padding: 0;
  box-sizing: unset;
}
