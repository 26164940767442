body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  position: absolute;
  width: 100vw;
  top: 0;
  left: 0; }
  .modal-backdrop .modal-container {
    position: fixed;
    background-color: white;
    width: 70%;
    max-width: 400px;
    left: 50%;
    padding: 20px;
    border-radius: 5px;
    -webkit-transform: translate(-50%, calc(50vh - 50%));
    transform: translate(-50%, calc(50vh - 50%));
    -webkit-transition: -webkit-transform 200ms ease-out;
    transition: -webkit-transform 200ms ease-out;
    transition: transform 200ms ease-out;
    transition: transform 200ms ease-out, -webkit-transform 200ms ease-out; }

.errorModal .footer {
  margin-top: 28px;
  display: flex;
  justify-content: space-around; }

.error-button {
  background: #ca3c3c;
  color: white;
  border-radius: 4px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2); }

.component-link-button {
  color: rgb(0, 0, 238);
  color: -webkit-link;
  display: inline;
  -webkit-appearance: none;
  background-color: transparent;
  cursor: pointer;
  text-decoration: underline;
  border: none;
  padding: 0;
  box-sizing: unset;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

